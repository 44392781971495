<template>
  <div class="flex flex-col h-full px-8 py-6 bg-white">
    <header class="flex justify-between">
      <div class="w-full">
        <h1 class="sr-only">Analytics</h1>
        <div class="w-full flex items-center justify-between">
          <h1
            class="text-2xl leading-7 tracking-tight font-semibold text-gray-800 sm:text-2xl sm:truncate"
          >
            Analytics
          </h1>
          <span
            v-if="analytics && analytics.updatedAt"
            class="text-sm font-normal text-gray-600"
            >Last update:
            {{
              new Date(analytics.updatedAt.seconds * 1000).toLocaleString()
            }}</span
          >
        </div>
        <p class="text-sm font-normal text-gray-600 w-3/4 my-2">
          Analytics show aggregated statistics on email scans, detected risks,
          and user corrective actions (remediations).
        </p>
      </div>
    </header>

    <div
      v-if="isWaiting || isLoading"
      class="flex flex-col w-full mt-8 text-gray-700"
    >
      <p class="text-gray-800 font-bold">Fetching analytics...</p>
    </div>

    <div v-else-if="error" class="flex flex-col w-full mt-8 text-gray-700">
      <p class="text-gray-800 font-bold">{{ error }}</p>
    </div>

    <div v-else class="text-gray-700 flex flex-col">
      <div
        v-if="analytics && analytics.stats"
        class="flex flex-col w-full mt-8"
      >
        <span class="text-base font-bold">General Statistics</span>
        <div class="flex flex-wrap items-center gap-4 mt-4">
          <!-- Remove subtitles -->
          <Stats
            title="Total Scans"
            :value="analytics.stats.emailsScannedMessagesTotal"
          />
          <Stats
            title="Email Messages Scanned"
            :value="analytics.stats.emailsScannedMessagesUnique"
          />
          <Stats
            title="Email Threads Scanned"
            :value="analytics.stats.emailsScannedThreadsUnique"
          />
          <Stats
            title="Email Addresses Scanned"
            :value="analytics.stats.recipientsScannedEmailsUnique"
          />
          <Stats
            title="Domain Names Scanned"
            :value="analytics.stats.recipientsScannedDomainsUnique"
          />
          <Stats
            title="Senders"
            :value="analytics.stats.userSendersScannedEmailsUnique"
          />
        </div>

        <span class="text-base font-bold mt-8">Risks Detected</span>
        <div class="flex flex-wrap items-center gap-4 mt-4">
          <Stats
            title="Email Messages"
            :value="analytics.stats.riskyEmailsMessagesUnique"
          />
          <Stats
            title="Email Threads"
            :value="analytics.stats.riskyEmailsThreadsUnique"
          />
          <Stats
            title="Email Addresses"
            :value="analytics.stats.riskyRecipientsEmailsUnique"
          />
          <Stats
            title="Domain Names"
            :value="analytics.stats.riskyRecipientsDomainsUnique"
          />
          <Stats
            title="Content Locations"
            :value="analytics.stats.riskyContentLocationsUnique"
          />
          <Stats
            title="Content Categories"
            :value="analytics.stats.riskyContentCategoriesUnique"
          />
        </div>

        <!-- Individual Threats Detected -->
        <!-- <span class="text-base font-bold mt-8">Threat Statistics</span>
			<div class="flex flex-wrap items-center gap-4 mt-4">
				<Stats title="Threats Detected (Recipients)" :total="analytics.stats.threatsRecipientTotal" :unique="analytics.stats.threatsRecipientUnique" />
				<Stats title="Threats Detected (Content)" :total="analytics.stats.threatsContentTotal" :unique="analytics.stats.threatsContentUnique" />
				<Stats title="Remediation (Recipients)" :total="analytics.stats.actionsRecipientTotal" :unique="analytics.stats.actionsRecipientUnique" />
				<Stats title="Remediation (Content)" :total="analytics.stats.actionsContentTotal" :unique="analytics.stats.actionsContentUnique" />
			</div> -->

        <span class="text-base font-bold mt-8">User Approvals</span>
        <div class="flex flex-wrap items-center gap-4 mt-4">
          <Stats
            title="Email Messages Approved"
            :value="analytics.stats.feedbacksReceivedMessagesUnique"
          />
          <Stats
            title="Email Threads Approved"
            :value="analytics.stats.feedbacksReceivedThreadsUnique"
          />
          <Stats
            title="Email Addresses Approved"
            :value="analytics.stats.recipientsFeedbackOptoutEmailsUnique"
          />
          <!-- <Stats title="Domain Names Approved" :value="analytics.stats.recipientsFeedbackOptoutDomainsUnique" /> -->
          <Stats
            title="Senders"
            :value="analytics.stats.userFeedbackReceivedEmailsUnique"
          />
        </div>
      </div>

      <div
        v-if="analytics && analytics.metrics"
        class="flex flex-col w-full mt-8"
      >
        <span class="text-base font-bold">General Metrics</span>
        <div class="flex flex-wrap items-center gap-4">
          <Metric
            title="Email Recipients Scanned"
            :data="mappedGlobalRecipientEmails"
            :samples="analytics.metrics.globalRecipientEmails.samples"
          />
          <Metric
            title="Domain Names Scanned"
            :data="mappedGlobalRecipientDomains"
            :samples="analytics.metrics.globalRecipientDomains.samples"
          />
          <Metric
            title="Senders"
            :data="mappedGlobalSenderEmails"
            :samples="analytics.metrics.globalSenderEmails.samples"
          />
        </div>

        <span class="text-base font-bold mt-8">Recipient Scan Metrics</span>
        <div class="flex flex-wrap items-center gap-4">
          <Metric
            title="Threat Level"
            :data="mappedRecipientThreats"
            :samples="analytics.metrics.recipientThreats.samples"
          />
          <!-- <Metric title="Remediation" :data="mappedRecipientActions" :samples="analytics.metrics.recipientActions.samples" /> -->
          <Metric
            title="Risky Domain Names"
            :data="mappedRiskyRecipientDomains"
            :samples="analytics.metrics.riskyRecipientDomains.samples"
          />
          <Metric
            title="Risky Email Addresses"
            :data="mappedRiskyRecipientEmails"
            :samples="analytics.metrics.riskyRecipientEmails.samples"
          />
        </div>

        <span class="text-base font-bold mt-8">Content Scan Metrics</span>
        <div class="flex flex-wrap items-center gap-4">
          <Metric
            title="Threat Level"
            :data="mappedContentThreats"
            :samples="analytics.metrics.contentThreats.samples"
          />
          <!-- <Metric title="Remediation" :data="mappedContentActions" :samples="analytics.metrics.contentActions.samples" /> -->
          <Metric
            title="Risky Categories"
            :data="mappedRiskyContentCategories"
            :samples="analytics.metrics.riskyContentCategories.samples"
          />
          <Metric
            title="Risky Locations"
            :data="mappedRiskyContentLocations"
            :samples="analytics.metrics.riskyContentLocations.samples"
          />
        </div>

        <span class="text-base font-bold mt-8">Approval Metrics</span>
        <div class="flex flex-wrap items-center gap-4">
          <!-- <Metric title="Feedback safe recipient domains" :data="mappedFeedbackSafeRecipientDomains" :samples="analytics.metrics.feedbackSafeRecipientDomains.samples" /> -->
          <!-- <Metric title="Recipients marked safe (domains)" :data="mappedFeedbackSafeRecipientEmails" :samples="analytics.metrics.feedbackSafeRecipientEmails.samples" /> -->
          <Metric
            title="Email Recipients (by Sender)"
            :data="mappedFeedbackSenderEmails"
            :samples="analytics.metrics.feedbackSenderEmails.samples"
          />
        </div>
      </div>
    </div>

    <ToastNotification :toast="toast" />
    <DefaultNotification :notification="notification" />
  </div>
</template>

<script>
// Components
import ToastNotification from "@/components/notification/ToastNotification";
import DefaultNotification from "@/components/notification/DefaultNotification";

// Adapter and User classes, etc.
import { Adapter } from "@/utilities/adapter";
import enums from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { grpcErrorToString } from "@/utilities/helpers";
import { v4 } from "uuid";
import Stats from "./Stats.vue";
import Metric from "./Metric.vue";

const ADAPTER = new Adapter();
const ENUMS = enums;
const NAME = "Settings";

export default {
  name: NAME,
  site_title: NAME,
  components: {
    ToastNotification,
    DefaultNotification,
    Stats,
    Metric,
  },

  data() {
    return {
      name: NAME,
      // UI Settings
      isAccessible: false, // for admin check
      isWaiting: false,

      isLoading: true,
      analytics: null,
      error: null,

      traceId: null,
      tenant: {},
      notification: {
        isShown: false,
        title: "",
        details: "",
      },
      toast: {
        isShown: false,
        message: "",
      },

      threats: {
        0: "Nil",
        1: "Nan",
        2: "Zero",
        3: "Low",
        4: "Medium",
        5: "High",
        6: "One",
      },

      actions: {
        0: "Allow",
        1: "Warn",
        2: "Deny",
        3: "Skip",
      },

      contentCategories: {
        0: "Financial",
        1: "Health",
        2: "Privacy",
        3: "Custom",
        4: "Utility",
        5: "Compliance",
        6: "Other",
      },

      contentLocations: {
        0: "Subject",
        1: "Body",
        2: "Filenames",
        3: "Attachments",
      },
    };
  },

  computed: {
    mappedRiskyRecipientDomains() {
      return this.analytics.metrics.riskyRecipientDomains.dataList
        .map((item) => ({
          value: item.domain.value,
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedRiskyRecipientEmails() {
      return this.analytics.metrics.riskyRecipientEmails.dataList
        .map((item) => ({
          value: item.email.value,
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedRecipientThreats() {
      return this.analytics.metrics.recipientThreats.dataList
        .map((item) => ({
          value: this.threats[item.threat],
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedRecipientActions() {
      return this.analytics.metrics.recipientActions.dataList
        .map((item) => ({
          value: this.actions[item.action],
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedRiskyContentCategories() {
      return this.analytics.metrics.riskyContentCategories.dataList
        .map((item) => ({
          value: this.contentCategories[item.category],
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedRiskyContentLocations() {
      return this.analytics.metrics.riskyContentLocations.dataList
        .map((item) => ({
          value: this.contentLocations[item.location],
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedContentThreats() {
      return this.analytics.metrics.contentThreats.dataList
        .map((item) => ({
          value: this.threats[item.threat],
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedContentActions() {
      return this.analytics.metrics.contentActions.dataList
        .map((item) => ({
          value: this.actions[item.action],
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedFeedbackSafeRecipientDomains() {
      return this.analytics.metrics.feedbackSafeRecipientDomains.dataList
        .map((item) => ({
          value: item.domain.value,
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedFeedbackSafeRecipientEmails() {
      return this.analytics.metrics.feedbackSafeRecipientEmails.dataList
        .map((item) => ({
          value: item.email.value,
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedFeedbackSenderEmails() {
      return this.analytics.metrics.feedbackSafeRecipientEmails.dataList
        .map((item) => ({
          value: item.email.value,
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedGlobalRecipientDomains() {
      return this.analytics.metrics.globalRecipientDomains.dataList
        .map((item) => ({
          value: item.domain.value,
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedGlobalRecipientEmails() {
      return this.analytics.metrics.globalRecipientEmails.dataList
        .map((item) => ({
          value: item.email.value,
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
    mappedGlobalSenderEmails() {
      return this.analytics.metrics.globalSenderEmails.dataList
        .map((item) => ({
          value: item.email.value,
          count: item.count,
        }))
        .sort((a, b) => b.count - a.count);
    },
  },

  methods: {
    async fetchAnalytics() {
      this.isLoading = true;
      this.toast = { isShown: true, message: "Fetching analytics..." };
      try {
        const result = await ADAPTER.getAnalytics(this.traceId);
        if (!result.data) {
          this.error = "Could not fetch analytics. Please try again later.";
        } else {
          this.analytics = result.data;
        }
      } catch (err) {
        this.notification = {
          type: "error",
          title: "An Error Occurred",
          details: `${err.message}. [${grpcErrorToString(err.code)}].`,
          isShown: true,
        };
        this.error = "Could not fetch analytics. Please try again later.";
      } finally {
        this.isLoading = false;
        this.toast.isShown = false;
      }
    },

    // 📡 COMMS
    // Fetch the tenant object from the backend
    async getTenant() {
      return new Promise(async (resolve, reject) => {
        this.isWaiting = true;

        this.toast = {
          isShown: true,
          message: "Fetching tenant...",
        };

        ADAPTER.getTenant(this.traceId)
          .then((tenant_obj) => {
            console.log("💢");
            console.log(tenant_obj);
            this.tenant = tenant_obj;
          })
          .catch((err) => {
            // show error notification
            this.notification = {
              type: "error",
              title: "An Error Occurred",
              details: `${err.message}. [${grpcErrorToString(err.code)}].`,
              isShown: true,
            };
            reject();
          })
          .finally(() => {
            this.isWaiting = false;
            this.toast.isShown = false;
            resolve();
          });
      });
    },

    /**
     * ✅ This method will do an admin check before the user can access the page.
     * 📡 Will communicate with backend using adapter.
     */
    async adminGuard() {
      return new Promise(async (resolve, reject) => {
        ADAPTER.activate(this.traceId).then(async (result) => {
          this.buttonText = result.bottle.message.value;
          if (result.role === ENUMS.Role.ROLE_ADMIN) {
            // If admin, proceed normally
            console.log(
              "%cYou have the right to view this page.",
              "color: #B4D455"
            );
            this.isAccessible = true;
            resolve();
          } else if (result.role === ENUMS.Role.ROLE_USER) {
            // Else if user, redirect immediately to /endpoints page
            console.log(
              "%cYou don't have the right to view this page. Redirecting...",
              "color: #FE2020"
            );
            this.$router.push({ path: "/integrations" });
          }
        });
      });
    },
  },

  async created() {
    /**
     * Each Activate, we have to set the trace ID per page.
     * This will be referenced later by other requests.
     */
    this.traceId = v4();

    await this.adminGuard();
    await this.getTenant();
    await this.fetchAnalytics();
    console.log("Analytics Page Initialized");
  },
};
</script>
